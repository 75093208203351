const useDisabilityTotals = (accessibilityData: any) => {
  const disabilities = accessibilityData.map((data: any) => data.disability);
  const categoryData = accessibilityData.map((data: any) => ({
    disability: data.disability,
    total: data.totalError + data.totalWarning + data.totalNotice,
  }));
  const totalByDisability = categoryData.map((entry: any) => entry.total);
  const totalIssues = totalByDisability.reduce(
    (acc: any, curr: any) => acc + curr,
    0,
  );

  const totalPercentageByDisability = categoryData.map((entry: any) => ({
    disability: entry.disability,
    percentage: ((entry.total / totalIssues) * 100).toFixed(2),
  }));

  return { disabilities, totalByDisability, totalPercentageByDisability };
};

export default useDisabilityTotals;
