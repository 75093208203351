import styled from "styled-components";

const Wrapper = styled.p`
  font-size: 0.8rem;
  text-align: center;
  color: #c9c9c9;
  font-weight: normal;
  margin-top: 5rem;
  margin-bottom: 5rem;
`;

const Disclaimer = () => {
  return (
    <Wrapper>
      Aviso: Este ranking de accesibilidad se ha creado con el propósito de
      destacar el estado de conformidad con los criterios de accesibilidad web
      según las pautas de la WCAG (Web Content Accessibility Guidelines). La
      información presentada se ha recopilado utilizando la herramienta{" "}
      <a href="https://www.deque.com/axe/" target="_blank" rel="noreferrer">
        Axe Core
      </a>
      , que es una herramienta de código abierto para evaluar la accesibilidad
      de sitios web, en este caso solo se evalua el "homepage" o página inicial
      de cada sitio. Es importante tener en cuenta que esta evaluación
      automatizada puede no capturar todas las complejidades de la accesibilidad
      web y no reemplaza una auditoría exhaustiva realizada por expertos en
      accesibilidad. Además, la clasificación de accesibilidad proporcionada
      aquí puede cambiar con el tiempo a medida que los sitios web actualizan su
      contenido y diseño.
      <b>
        Este ranking se ofrece con el objetivo de fomentar la conciencia sobre
        la importancia de la accesibilidad web y no tiene la intención de
        difamar o perjudicar a ninguna entidad mencionada
      </b>
      . Si tiene alguna pregunta o inquietud sobre la precisión de la
      información presentada, le animamos a ponerse en contacto con nosotro al
      correo <i>mcastro@suricagrafica.cl </i>. Gracias por su comprensión y
      compromiso con la mejora de la accesibilidad web.
    </Wrapper>
  );
};

export default Disclaimer;
