import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../theme";

const LegendContainer = styled.div`
  margin-top: 20px;
`;

const LegendItem = styled.div`
  margin-bottom: 10px;
`;

const ColorCircle = styled.span`
  background-color: ${({ color }) => color};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
`;

const Legend = () => (
  <LegendContainer>
    <LegendItem>
      <ColorCircle color={COLORS.error} />
      Problemas críticos: afectan significativamente la accesibilidad del sitio.
    </LegendItem>
    <LegendItem>
      <ColorCircle color={COLORS.warning} />
      Problemas Moderados: Problemas menos críticos pero que aún requieren
      atención.
    </LegendItem>
    <LegendItem>
      <ColorCircle color={COLORS.notice} />
      Problemas leves: Información y sugerencias que no son problemas críticos.
    </LegendItem>
  </LegendContainer>
);

export default Legend;
