import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { analytics } from "../../analytics";
import { logEvent } from "firebase/analytics";

interface RadioButtonProps {
  selected?: boolean;
}

interface FilterCategoryProps {
  filters: string[];
  onChange?: (filter: string) => void;
}

const RadioButtonContainer = styled.div`
  display: flex;
  margin-top: 2rem;
  flex-wrap: wrap;
`;

const RadioButton = styled.div<RadioButtonProps>`
  font-size: 1.1rem;
  appearance: none;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.green : "#fff"};
  color: ${(props) => (props.selected ? "#fff" : "#333")};
  padding: 0.5rem 1rem;
  margin: 4px;
  cursor: pointer;
  border-radius: 20px;
  &:hover {
    background-color: ${({ selected, theme }) =>
      selected ? theme.colors.green : theme.colors.softGrey};
  }
`;

const FilterCategory = ({ filters, onChange }: FilterCategoryProps) => {
  const [selectedOption, setSelectedOption] = useState("Todas");
  const radioButtonsRef = useRef<Array<HTMLDivElement | null>>([]);
  const focusedIndex = useRef<number | null>(null);
  const allFilters = ["Todas", ...filters];

  const handleOptionChange = (option: string) => {
    logEvent(analytics, "change_category", { value: option });
    setSelectedOption(option);
    onChange && onChange(option);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
      const direction = event.key === "ArrowLeft" ? -1 : 1;
      const newIndex = (focusedIndex.current || 0) + direction;

      if (newIndex >= 0 && newIndex < allFilters.length) {
        focusedIndex.current = newIndex;
        handleOptionChange(allFilters[newIndex]);
      }
    }
  };

  useEffect(() => {
    if (focusedIndex.current !== null) {
      const radio = document.getElementById(
        `radio-${allFilters[focusedIndex.current]}`
      );
      if (radio) {
        radio.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedIndex.current]);

  return (
    <RadioButtonContainer
      role="radiogroup"
      aria-label="Filtrar por categoria"
      aria-activedescendant={`radio-${selectedOption}`}
    >
      {allFilters.map((filter, index) => (
        <RadioButton
          id={`radio-${filter}`}
          ref={(el) => (radioButtonsRef.current[index] = el)}
          key={`radio-${filter}`}
          role="radio"
          tabIndex={selectedOption === filter ? 0 : -1}
          aria-checked={selectedOption === filter}
          selected={selectedOption === filter}
          onClick={() => handleOptionChange(filter)}
          onKeyDown={handleKeyDown}
        >
          {filter}
        </RadioButton>
      ))}
    </RadioButtonContainer>
  );
};

export default FilterCategory;
