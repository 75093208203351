import { ToggleButtonGroup } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import MuiToggleButton from "@mui/material/ToggleButton";

import { styled } from "@mui/material/styles";
import { logEvent } from "firebase/analytics";
import { analytics } from "../analytics";

const ToggleButton = styled(MuiToggleButton)({
  "&.MuiToggleButton-standard": {
    color: "#282c34",
    fontWeight: "bold",
  },
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "#3AB298",
    fontWeight: "bold",
  },
});

interface FilterLevelProps {
  onChange?: (selected: string) => void;
  level: string;
}

const FilterLevel = ({ onChange, level }: FilterLevelProps) => {
  const [selected, setSelected] = useState(level);

  useEffect(() => {
    onChange && onChange(selected);
  }, [selected, onChange]);

  const handleChange = (_: React.MouseEvent<HTMLElement>, level: string) => {
    if (level === selected || level === null) return;
    setSelected(level);
    logEvent(analytics, "change_level", { value: level });
  };
  return (
    <>
      <p>Nivel de accesibilidad</p>
      <ToggleButtonGroup
        color="standard"
        value={selected}
        exclusive
        onChange={handleChange}
        aria-label="Nivel de accesibilidad"
        style={{ backgroundColor: "white", color: "white" }}
      >
        <ToggleButton aria-label="Mínimo" value="a">
          Mínimo (A)
        </ToggleButton>
        <ToggleButton aria-label="Medio" value="aa">
          Medio (AA)
        </ToggleButton>
        <ToggleButton aria-label="Alto " value="aaa">
          Alto (AAA)
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};

export default FilterLevel;
