import styled from "styled-components";
import Chip from "../../commons/Chip";

import {
  accessibilityCategoriesDictionary,
  getDisabilityByCode,
} from "../../utils/helpers";
import Accordion from "../../commons/Accordion";
import SelectorSection from "./SelectorSection";

const Description = styled.div`
  background: white;
  padding: 1rem;
  color: #333;
`;

const Recomendation = styled.div`
  background: white;
  padding: 1rem;
  color: #333;
  h3 {
    margin: 0;
  }
`;

const Link = styled.a`
  color: #1178a9;
`;

interface ScanResultListProps {
  issuesGrouped: any;
  color: string;
}

const SummaryContainer = styled.div`
  display: flex;
  color: ${({ color }) => color};
  font-weight: bold;
  padding: 1rem;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.lightGrey};
`;

const TitleSummary = styled.span`
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Affected = styled.span`
  font-weight: normal;
  margin-top: 0.5rem;
`;

const ScanResultList = ({ issuesGrouped, color }: ScanResultListProps) => {
  const getDisability = (code: string) => {
    const disability = getDisabilityByCode(code);
    return accessibilityCategoriesDictionary[disability]
      ? accessibilityCategoriesDictionary[disability].translation
      : disability;
  };

  return (
    <>
      {Object.keys(issuesGrouped).map((code, index) => (
        <Accordion key={`${code}-${index}`}>
          <Accordion.Summary uniqId={`${code}-${index}`}>
            <SummaryContainer>
              <TitleSummary>
                {issuesGrouped[code].length && (
                  <Chip color={color} label={issuesGrouped[code].length} />
                )}
                <span>{code}</span>
              </TitleSummary>
              <Affected>Personas afectadas: {getDisability(code)}</Affected>
            </SummaryContainer>
          </Accordion.Summary>

          <Accordion.Panel uniqId={`${code}-${index}`}>
            <Description>{issuesGrouped[code][0].message}</Description>

            <SelectorSection
              issues={issuesGrouped[code]}
              uniqId={`${code}-${index}`}
            />

            <Recomendation>
              <b>Acción recomendada</b>
              <p>
                {issuesGrouped[code][0].runnerExtras.description}.{" "}
                <Link
                  href={issuesGrouped[code][0].runnerExtras.helpUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  Mas información
                </Link>{" "}
              </p>
            </Recomendation>
          </Accordion.Panel>
        </Accordion>
      ))}
    </>
  );
};

export default ScanResultList;
