import Layout from "../../commons/Layout";
import HeaderLogo from "../../components/LogoHeader";
import { COLORS } from "../../theme";
import TabGroup from "../../commons/TabsGroup";

import { groupByKey } from "../../utils/helpers";
import { Issue } from "../Home/index.types";
import { useState } from "react";
import IssueCardList from "../../components/ScanResultList";
import { scanWebsite } from "../../services";
import SearchScan from "../../components/SearchScan";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import styled from "styled-components";
import Disclaimer from "../../components/Disclaimer";
// import { remoteConfig } from "../../analytics";
// import { fetchAndActivate, getAll } from "firebase/remote-config";
import { ScanResult } from "./index.types";

const colorByType: any = {
  error: COLORS.error,
  warning: COLORS.warning,
  notice: COLORS.notice,
};

const labelByType: any = {
  error: "criticos",
  warning: "moderados",
  notice: "leves",
};

const idByType: any = {
  error: 1,
  warning: 2,
  notice: 3,
};

const Title = styled.h1`
  font-size: 1.5rem;
  span {
    color: ${({ theme }) => theme.colors.softGrey};
  }
`;

const ScanResultPageContainer = styled.div`
  width: 100%;
  max-width: 800px;
`;

const ScanResultPage = () => {
  const ACTIVE_SCAN = true;
  const [scanResults, setScanResults] = useState<ScanResult[]>([]);
  const [isReady, setIsReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPageUrl, setCurrentPageUrl] = useState("");
  const [error, setError] = useState<string | null>(null);

  const doScanToPage = async (pageUrl: string) => {
    setIsLoading(true);
    try {
      const { result } = await scanWebsite(pageUrl.toString());
      const issues: Issue[] = result.issues;
      const groupedByType = groupByKey(issues, "type");
      const groupedByTypeAndCode = Object.keys(groupedByType).map((type) => {
        return {
          label: labelByType[type],
          color: colorByType[type],
          type: type,
          typeCode: idByType[type],
          issues: groupByKey(groupedByType[type], "code"),
          chipValue: groupedByType[type].length,
        };
      });
      setScanResults(groupedByTypeAndCode);
      setIsLoading(false);
      setIsReady(true);
      setError(null);
      setCurrentPageUrl(pageUrl);
    } catch (error) {
      setIsLoading(false);
      setError(
        "Ha ocurrido un error al validar la accesibilidad de esta página"
      );
    }
  };

  const isAllowedScan = (pageUrl: string) => {
    if (pageUrl.length < 5) return;
    if (!ACTIVE_SCAN) {
      setError("Servicio desactivado temporalmente");
      return;
    }
    const textClear =
      typeof pageUrl === "string" && pageUrl.trim().length > 0
        ? pageUrl.trim()
        : "";
    const isValid = textClear.match(
      /^(?!https?|ftp:\/\/)(www\.)?[^\s/$.?#]+\.(com|cl)$/
    );
    return isValid;
  };

  const setPageToScan = (pageUrl: string) => {
    const isValid = isAllowedScan(pageUrl);
    if (isValid) {
      doScanToPage(pageUrl);
    } else {
      setIsReady(false);
      setError("Debe ser un formato válido de url. Ejemplo: www.mipagina.cl");
    }
  };

  // const fetchToggles = async () => {
  //   return fetchAndActivate(remoteConfig)
  //     .then((res) => {
  //       // console.log("res", res);
  //       return getAll(remoteConfig);
  //     })
  //     .catch(console.error);
  // };

  // useEffect(() => {
  //   const doAsync = async () => {
  //     const result: any = await fetchToggles();
  //     // console.log("result", result);
  //   };
  //   doAsync();
  // }, []);

  return (
    <Layout>
      <HeaderLogo />
      <ScanResultPageContainer>
        <SearchScan
          handleClick={(pageUrl: string) => setPageToScan(pageUrl)}
          disabled={isLoading}
          error={error}
        />

        {ACTIVE_SCAN && (
          <>
            {isReady && !isLoading && (
              <>
                <Title id="results-title">
                  Análisis de accesibilidad web para{" "}
                  <span>{currentPageUrl}</span>
                </Title>

                <TabGroup items={scanResults} defaultValue="criticos">
                  {scanResults.map((scanResult, index) => (
                    <TabGroup.Content key={index} label={scanResult.label}>
                      <IssueCardList
                        issuesGrouped={scanResult.issues}
                        color={scanResult.color}
                      />
                    </TabGroup.Content>
                  ))}
                </TabGroup>
              </>
            )}

            {isLoading && (
              <Box sx={{ width: "100%" }}>
                <p>Analizando</p>
                <LinearProgress />
              </Box>
            )}
          </>
        )}

        <Disclaimer />
      </ScanResultPageContainer>
    </Layout>
  );
};

export default ScanResultPage;
