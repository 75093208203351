export const COLORS = {
  error: '#A32D15',
  warning: '#7A4C00',
  notice: '#187BBE',
};

const theme = {
  colors: {
    green: '#3AB298',
    darkGrey: '#282c34',
    softGrey: '#c9c9c9',
    lightGrey: '#EAEFF2',
    grey: '#999',
    error: COLORS.error,
    warning: COLORS.warning,
    notice: COLORS.notice,
  },
};

export default theme;
