import styled from 'styled-components';
import { COLORS } from '../../theme';
import Chip from '../../commons/Chip';

const TableRankMobileContainer = styled.div`
  width: 100%;
  margin-top: 3rem;
`;

const Content = styled.div`
  background: white;
  width: 100%;
  padding: 0.5rem;
  border-radius: 10px;
  color: #333;
  margin-bottom: 1rem;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
  flex: 1;
`;

const ListItem = styled.div`
  border-top: 1px solid #ccc;
  display: flex;
`;

const Rank = styled.b`
  font-size: 1.5rem;
  margin-right: 0.5rem;
`;

const Page = styled.div`
  display: flex;
  font-size: 1rem;
  margin-right: 1rem;
  color: #333;
  span:first-child {
    font-weight: bold;
    margin-right: 0.5rem;
  }
  span:last-child {
    margin-left: 0.5rem;
  }
`;

const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;
`;

const Domain = styled.a`
  color: #0d6078;
`;

const TableRankMobile = ({ data }: any) => {
  return (
    <TableRankMobileContainer>
      {data.map((row: any, index: number) => (
        <Content
          key={index}
          aria-label={`posicion ${index + 1}, ${row.domain}, categoria ${
            row.category
          }, problemas: ${row.errors} criticos, ${row.warnings} moderados, ${
            row.notices
          } leves`}
        >
          <HeaderBox>
            <Rank aria-label={`Ranking N° ${index + 1}`}># {index + 1}</Rank>
            <Page>
              <Domain
                href={`https://${row.domain}`}
                target='_blank'
                rel='noreferrer'
              >
                {row.domain}
              </Domain>
              <span aria-hidden>-</span>
              <span aria-label={`Categoria ${row.category}`}>
                {row.category}
              </span>
            </Page>
          </HeaderBox>

          <ListItem>
            <Item aria-label={`${row.errors} problemas críticos`}>
              <Chip color={COLORS.error} label={row.errors} />
              <p>críticos</p>
            </Item>
            <Item aria-label={`${row.warning} problemas moderados`}>
              <Chip color={COLORS.warning} label={row.warnings} />
              <p>moderados</p>
            </Item>
            <Item aria-label={`${row.notices} problemas leves`}>
              <Chip color={COLORS.notice} label={row.notices} />
              <p>leves</p>
            </Item>
          </ListItem>
        </Content>
      ))}
    </TableRankMobileContainer>
  );
};

export default TableRankMobile;
