import styled from 'styled-components';

interface StyledThProps {
  background?: string;
}

export const ContainerTable = styled.div`
  width: 100%;
  @media (max-width: 800px) {
    overflow: scroll;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  table-layout: auto;
`;

export const StyledTh = styled.th<StyledThProps>`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  background-color: ${(props) => props.background || '#555'};
  color: white;
  background-image: none;
  @media (min-width: 800px) {
    min-width: 100px;
  }
`;

export const StyledThScope = styled.th<StyledThProps>`
  padding: 0 8px;
  color: black;
`;

export const StyledTr = styled.tr`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  &:nth-child(even) {
    background-color: #f1f1f1;
  }
  &:nth-child(odd) {
    background-color: white;
  }
`;

export const StyledTd = styled.td`
  font-size: 1rem;
  padding: 8px;
  border: 1px solid #ddd;
  color: black;
`;

export const StyledTdColor = styled(StyledTd)`
  font-weight: bold;
  color: ${(props) => props.color || 'black'};
`;

export const Domain = styled.a`
  color: #0d6078;
  font-weight: normal;
`;

export const Caption = styled.caption`
  margin-bottom: 1rem;
`;
