import styled from "styled-components";
import Accordion from "../../../commons/Accordion";

const Selector = styled.div`
  font-size: 1rem;
  font-weight: normal;
  color: #555;
  margin-bottom: 0.5rem;
`;

const TitleHeading = styled.h3`
  color: #333;
  margin: 0;
`;

const SelectorSectionContainer = styled.div`
  padding: 1rem;
`;

const SelectorSection = ({
  issues,
  uniqId,
}: {
  issues: any;
  uniqId: string;
}) => {
  return (
    <SelectorSectionContainer>
      <Accordion uniqId={uniqId}>
        <Accordion.Summary>
          <TitleHeading>Selectores (ver más)</TitleHeading>
        </Accordion.Summary>
        <Accordion.Panel>
          {issues.map((issue: any, index: number) => (
            <Selector key={`${uniqId}-${index}`}>{issue.selector}</Selector>
          ))}
        </Accordion.Panel>
      </Accordion>
    </SelectorSectionContainer>
  );
};

export default SelectorSection;
