import { useState } from "react";
import styled from "styled-components";

const Input = styled.input`
  background-color: transparent;
  outline: none;
  height: 35px;
  font-size: 15px;
  border: 0;
  width: 100%;
`;

const SearchScanContainer = styled.form`
  min-width: 500px;
  background-color: #fff;
  position: relative;
  border-radius: 30px;
  padding: 3px 50px 3px 10px;
`;

const Button = styled.button`
  font-size: 1rem;
  font-weight: bold;
  position: absolute;
  right: 4px;
  top: 4px;
  background: ${({ theme }) => theme.colors.green};
  border-radius: 30px;
  border: 0;
  color: #fff;
  min-width: 120px;
  height: 35px;
  outline: 0;
  &:hover {
    cursor: pointer;
    background: #248772;
  }
  &:disabled {
    cursor: not-allowed;
    background: ${({ theme }) => theme.colors.grey};
  }
`;

const TextError = styled.p`
  color: white;
`;

interface SearchScanProps {
  handleClick?: (selected: string) => void;
  disabled: boolean;
  error?: string | null;
}

const SearchScan = ({ handleClick, disabled, error }: SearchScanProps) => {
  const [urlToScan, setUrlToScan] = useState("");

  const handleChange = (event: any) => {
    const text = event.target.value.toString();
    if (text.length > 5) {
      setUrlToScan(text);
    }
  };

  const handleOnSubmit = (event: any) => {
    handleClick && handleClick(urlToScan);
    event.preventDefault();
  };

  return (
    <>
      {error && <TextError aria-live="polite">{error}</TextError>}
      <SearchScanContainer onSubmit={handleOnSubmit}>
        <Input
          aria-label="Ingrese una url para escanear accesibilidad"
          required
          type="text"
          id="urlInput"
          name="url"
          placeholder="www.example.cl"
          onChange={handleChange}
          pattern="(?!https?|ftp:\/\/)((www.)?)([a-zA-z0-9\-_]+)(.com|.cl)"
          disabled={disabled}
          title="Ingresa url sin http o https. Ejemplo: www.mipagina.cl o mipagina.cl "
          autoComplete="url"
        />
        <Button type="submit" disabled={disabled} aria-label="Escanear">
          Escanear
        </Button>
      </SearchScanContainer>
    </>
  );
};
export default SearchScan;
