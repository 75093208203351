import styled from "styled-components";
import { useTabsContext } from ".";
// import Chip from "../Chip";

interface TabContainerProps {
  isselected: boolean | string;
  activecolor: string;
}

interface TabProps {
  label: string;
  activeColor: string;
  value: any;
}

const TabContainer = styled.button<TabContainerProps>`
  font-size: 1.2rem;
  border: inherit;
  display: flex;
  flex: 1;
  min-width: 200px;
  align-items: center;
  text-align: center;
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: ${({ isselected, activecolor }) =>
    isselected ? `5px solid ${activecolor}` : "2px solid transparent"};
  color: ${({ isselected, activecolor }) =>
    isselected ? activecolor : "#757575"};
  font-weight: ${({ isselected }) => (isselected ? "bold" : "normal")};
`;

const Tab = ({ label, activeColor, value }: TabProps) => {
  const context = useTabsContext();
  if (!context) {
    throw new Error("Tab must be used within a TabGroup");
  }
  const { selectedTab, setSelectedTab } = context;
  const isSelected = selectedTab === label;
  const handleChange = (e: any) => {
    e.stopPropagation();
    setSelectedTab(label);
  };

  return (
    <TabContainer
      id={`tab-${label}`}
      aria-selected={isSelected}
      aria-controls={`tabpanel-${label}`}
      onClick={handleChange}
      isselected={isSelected}
      activecolor={activeColor}
    >
      {/* {value && <Chip color={activeColor} label={value} />} */}
      {value} {label}
    </TabContainer>
  );
};

export default Tab;
