import { useState, createContext, useContext } from "react";
import styled from "styled-components";
import Tab from "./Tab";
import TabContent from "./TabContent";

interface TabGroupContextType {
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
}

const TabGroupContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 800px;
  background: white;
`;

const TabGroupContext = createContext<TabGroupContextType>(
  {} as TabGroupContextType
);

export const useTabsContext = () => {
  const context = useContext(TabGroupContext);
  if (!Object.keys(context).length) {
    throw new Error(
      "TabGroupContext provider is not found. Make sure to wrap your components with TabGroup."
    );
  }
  return context;
};

const TabGroup = ({ items, defaultValue, children }: any) => {
  const [selectedTab, setSelectedTab] = useState(defaultValue);

  const handleChange = (id: any) => {
    setSelectedTab(id);
  };

  return (
    <TabGroupContext.Provider
      value={{ selectedTab, setSelectedTab: handleChange }}
    >
      <TabGroupContainer role="tablist" aria-labelledby="results-title">
        {items.map((item: any, index: number) => (
          <Tab
            key={index}
            label={item.label}
            activeColor={item.color}
            value={item.chipValue}
          />
        ))}
      </TabGroupContainer>

      {children}
    </TabGroupContext.Provider>
  );
};

TabGroup.Tab = Tab;
TabGroup.Content = TabContent;

export default TabGroup;
