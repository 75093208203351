const useWeightedSorting = (): Function => {
  const WEIGHT_ERROR = 3;
  const WEIGHT_WARNING = 2;
  const WEIGHT_NOTICE = 1;

  const comparePages = (a: any, b: any) => {
    const scoreA =
      WEIGHT_ERROR * a.errors +
      WEIGHT_WARNING * a.warnings +
      WEIGHT_NOTICE * a.notices;

    const scoreB =
      WEIGHT_ERROR * b.errors +
      WEIGHT_WARNING * b.warnings +
      WEIGHT_NOTICE * b.notices;

    // (menor puntaje primero)
    return scoreA - scoreB;
  };

  const getSorting = (data: any) => {
    const sortedData = [...data].sort(comparePages);
    const sortedDataWithRank = sortedData.map((item, index) => ({
      ...item,
      rank: index + 1,
    }));
    return sortedDataWithRank;
  };
  return getSorting;
};

export default useWeightedSorting;
