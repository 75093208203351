import { TableProps } from "./index.types";
import {
  Caption,
  Domain,
  StyledTable,
  StyledTd,
  StyledTdColor,
  StyledTh,
  StyledThScope,
  StyledTr,
  ContainerTable,
} from "./index.style";
import { COLORS } from "../../theme";

const Table = ({ data }: TableProps) => {
  return (
    <ContainerTable role="region" aria-labelledby="tableCaption" tabIndex={0}>
      <StyledTable>
        <Caption id="tableCaption">
          Ranking de accesibilidad web en Chile basado en estándar internacional
          <a
            href="https://www.w3.org/WAI/standards-guidelines/wcag/"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            WCAG 2.1
          </a>
        </Caption>
        <thead>
          <tr>
            <StyledTh scope="col">Ranking</StyledTh>
            <StyledTh scope="col">Página</StyledTh>
            <StyledTh scope="col" background="#D93C2C">
              Problemas Críticos
            </StyledTh>
            <StyledTh scope="col" background="#A86701">
              Problemas Moderados
            </StyledTh>
            <StyledTh scope="col" background="#187BBE">
              Problemas Leves
            </StyledTh>
            <StyledTh scope="col">Categoría</StyledTh>
            <StyledTh scope="col">Ver detalle</StyledTh>
          </tr>
        </thead>
        <tbody>
          {data.map((row: any, index: number) => (
            <StyledTr key={row.rank}>
              <StyledTd># {index + 1}</StyledTd>

              <StyledThScope scope="row">
                <Domain
                  href={`https://${row.domain}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {row.domain}
                </Domain>
              </StyledThScope>
              <StyledTdColor color={COLORS.error}>{row.errors}</StyledTdColor>
              <StyledTdColor color={COLORS.warning}>
                {row.warnings}
              </StyledTdColor>
              <StyledTdColor color={COLORS.notice}>{row.notices}</StyledTdColor>
              <StyledTd>{row.category}</StyledTd>
              <StyledTd>
                <small>Proximamente</small>
              </StyledTd>
            </StyledTr>
          ))}
        </tbody>
      </StyledTable>
    </ContainerTable>
  );
};

export default Table;
