// firebase.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getRemoteConfig } from "firebase/remote-config";

const firebaseConfig = {
  apiKey: "AIzaSyD6PJKUOfri4M_GMQ7GCOU35PRJw-vvZ1g",
  authDomain: "accessibility-rank.firebaseapp.com",
  projectId: "accessibility-rank",
  storageBucket: "accessibility-rank.appspot.com",
  messagingSenderId: "538142005277",
  appId: "1:538142005277:web:516123cfda524c41a48ef4",
  measurementId: "G-8YBZ5KDBM2",
};

const app = initializeApp(firebaseConfig);
const remoteConfig: any = getRemoteConfig(app);
const analytics = getAnalytics(app);

export { remoteConfig, analytics };
