import styled from "styled-components";

export const PercentSection = styled.div`
  margin: 4rem 0;
  max-width: 650px;
  color: white;
  font-size: 1.2rem;
  display: flex;
  align-items: flex-start;
  b {
    font-size: 2rem;
    margin-right: 0.5rem;
  }
  p {
    margin: 0;
  }

  @media (max-width: 800px) {
    display: inline-block;
    p {
      display: inline;
    }
    a {
      font-size: 1rem;
    }
  }
`;

const HighlightQuote = () => {
  return (
    <PercentSection>
      <b>17% </b>
      <p>
        de la población en Chile presenta algún grado de discapacidad, lo que
        equivale a 3.291.602 personas.{" "}
        <a
          href="https://observatorio.ministeriodesarrollosocial.gob.cl/endide-2022"
          target="_black"
          rel="noreferrer"
        >
          ENDIDE 2022
        </a>
      </p>
    </PercentSection>
  );
};

export default HighlightQuote;
