import styled from "styled-components";
import a11yLogo from "../../assets/a11y-icon-2.png";
import LogoSVG from "../../commons/LogoSVG";

export const ImageLogo = styled.img`
  width: auto;
  height: 80px;
  margin: 2rem 0 0 0;
`;

export const HeaderLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderLogo = () => {
  return (
    <HeaderLogoContainer>
      <a href="/" aria-label="Ir al Inicio">
        <ImageLogo src={a11yLogo} alt="" />
      </a>
      <LogoSVG />
    </HeaderLogoContainer>
  );
};

export default HeaderLogo;
