import { createContext, useContext, useState } from "react";
import styled from "styled-components";

const SummaryContainer = styled.button`
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  font-size: 15px;
  transition: 0.4s;
  padding: 0;
  background: none;
`;

interface PanelContainerProps {
  active: boolean;
}

const PanelContainer = styled.div<PanelContainerProps>`
  background-color: white;
  overflow: hidden;
  display: ${({ active }) => (active ? "block" : "none")};
  transition: 0.4s;
  overflow: auto;
`;

const AccordionContainer = styled.div`
  margin-bottom: 0.2rem;
`;

interface AccordionContextType {
  open: boolean;
  setOpen: (state: boolean) => void;
}

const AccordionContext = createContext<AccordionContextType>(
  {} as AccordionContextType
);

export const useAccordionContext = () => {
  const context = useContext(AccordionContext);
  if (!Object.keys(context).length) {
    throw new Error(
      "TabGroupContext provider is not found. Make sure to wrap your components with TabGroup."
    );
  }
  return context;
};

const Summary = ({ children, uniqId }: any) => {
  const { open, setOpen } = useAccordionContext();
  return (
    <SummaryContainer
      type="button"
      aria-expanded={open}
      aria-controls={`panel-${uniqId}`}
      onClick={() => setOpen(!open)}
    >
      {children}
    </SummaryContainer>
  );
};

const Panel = ({ children, uniqId }: any) => {
  const { open } = useAccordionContext();
  return (
    <PanelContainer id={`panel-${uniqId}`} role="region" active={open}>
      {children}
    </PanelContainer>
  );
};

const CustomAccordion = ({ children }: any) => {
  const [open, setOpen] = useState(false);
  const handleChange = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  return (
    <AccordionContext.Provider value={{ open, setOpen: handleChange }}>
      <AccordionContainer>{children}</AccordionContainer>
    </AccordionContext.Provider>
  );
};

CustomAccordion.Summary = Summary;
CustomAccordion.Panel = Panel;

export default CustomAccordion;
