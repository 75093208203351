import { BarChart } from '@mui/x-charts/BarChart';
import { Box } from '@mui/material'; // Importa el componente Box de Material-UI
import useDisabilityTotals from '../../hooks/useDisabilityTotals';

const BarChartSummary = ({ summaryForChart }: any) => {
  const { disabilities, totalPercentageByDisability } =
    useDisabilityTotals(summaryForChart);

  const totalPercentages = totalPercentageByDisability.map(
    (entry: any) => entry.percentage,
  );

  return (
    <div style={{ overflowX: 'auto', width: '100%' }}>
      <Box sx={{ width: '100%', maxWidth: '1200px', overflowX: 'auto' }}>
        <BarChart
          colors={['#A32D15', '#1f1f1e', '#187BBE']}
          sx={{
            '& .MuiChartsAxis-tickLabel tspan': {
              fontSize: '0.8rem',
              fill: '#fff',
            },
          }}
          slotProps={{
            legend: {
              labelStyle: {
                fontSize: 14,
                fill: '#fff',
              },
            },
          }}
          xAxis={[
            {
              id: 'categories',
              data: disabilities,
              scaleType: 'band',
              dataKey: 'category',
              valueFormatter: (category, context) =>
                context.location === 'tick'
                  ? category
                  : `% del total de errores`,
              colorMap: {
                type: 'ordinal',
                colors: [
                  '#ccebc5',
                  '#a8ddb5',
                  '#7bccc4',
                  '#4eb3d3',
                  '#2b8cbe',
                  '#08589e',
                ],
              },
            },
          ]}
          series={[
            {
              data: totalPercentages,
              // label: 'Total',
            },
          ]}
          width={1500}
          height={300}
        />
      </Box>
    </div>
  );
};

export default BarChartSummary;
