import axios from "axios";

const API_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_API_URL
    : process.env.REACT_APP_DEV_API_URL;

const PROJECT_ID = "accessibility-rank";

export const scanWebsite = async (url: string) => {
  try {
    const response = await axios.get(
      `${API_URL}/${PROJECT_ID}/us-central1/api/scan/${url}`
    );
    return response.data;
  } catch (error: any) {
    throw new Error(`Error scanning website: ${error.message}`);
  }
};
