import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { accessibilityCategoriesDictionary } from '../../utils/helpers';
import useDisabilityTotals from '../../hooks/useDisabilityTotals';

const TableDescriptions = ({ summaryForChart }: any) => {
  const { totalPercentageByDisability } = useDisabilityTotals(summaryForChart);
  const getPercentageByDisability = (disabilityTranslation: any) => {
    if (!totalPercentageByDisability.length) return 'N/A';
    const disabilityFound = totalPercentageByDisability.find(
      (disabilityPercentage: any) =>
        disabilityPercentage.disability === disabilityTranslation,
    );
    return disabilityFound?.percentage
      ? `${disabilityFound?.percentage} %`
      : 'Sin errores';
  };

  return (
    <TableContainer component={Paper} aria-label='Tabla de Descripciones'>
      <Table size='small' aria-label='Tabla de Descripciones'>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Total</TableCell>
            <TableCell>Problema</TableCell>
            <TableCell>Descripción</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(accessibilityCategoriesDictionary).map(
            ([disability, data]: any, index) => (
              <TableRow key={disability}>
                <TableCell align='center'>
                  {getPercentageByDisability(data.translation)}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {data.translation}
                </TableCell>
                <TableCell>{data.description}</TableCell>
              </TableRow>
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableDescriptions;
