const ACCESSIBILITY_ISSUES: any = {
  // Contraste visual
  visual: ['color-contrast', 'meta-viewport'],

  // Estructura de la página
  pageStructure: [
    'frame-tested',
    'frame-title',
    'frame-title-unique',
    'list',
    'listitem',
    'table-fake-caption',
    'region',
    'area-alt',
    'heading-order',
    'document-title',
    'empty-heading',
    'page-has-heading-one',
    'meta-refresh',
  ],

  // Navegación y operabilidad
  navigationOperability: [
    'button-name',
    'aria-hidden-focus',
    'scrollable-region-focusable',
    'tabindex',
    'skip-link',
  ],

  // Interactividad
  interactivity: [
    'link-name',
    'identical-links-same-purpose',
    // Otros problemas relacionados con la interactividad pueden agregarse aquí
  ],

  // Compatibilidad y adaptabilidad
  compatibilityAdaptability: [
    'aria-roledescription',
    'nested-interactive',
    'aria-required-parent',
    'aria-hidden-body',
  ],

  // Texto alternativo y multimedia
  altMedia: [
    'image-alt',
    'input-image-alt',
    'aria-tooltip-name',
    'image-redundant-alt',
    'video-caption',
  ],

  // Identificación y etiquetado
  identificationLabeling: [
    'landmark-no-duplicate-banner',
    'landmark-no-duplicate-contentinfo',
    'landmark-no-duplicate-main',
    'landmark-no-duplicate-complementary',
    'landmark-no-duplicate-form',
    'landmark-unique',
    'landmark-no-duplicate',
    'landmark-banner-is-top-level',
    'landmark-complementary-is-top-level',
    'landmark-contentinfo-is-top-level',
    'landmark-main-is-top-level',
    'landmark-no-roles',
    'landmark-one-main',
    'bypass',
    'duplicate-id-aria',
    'html-has-lang',
    'html-lang-valid',
    'landmark-unique',
    'label',
    'select-name',
    'aria-command-name',
    'aria-input-field-name',
  ],

  // Claridad y comprensión
  clarityComprehension: [
    'html-valid-content',
    'html-valid-attr',
    'aria-valid-attr',
    'aria-valid-role',
    'aria-valid-roles',
    'aria-required-attr',
    'aria-required-children',
    'aria-valid-attr-value',
    'aria-allowed-attr',
    'aria-allowed-role',
    'duplicate-id',
    'duplicate-id-active',
    'autocomplete-valid',
  ],
};

export default ACCESSIBILITY_ISSUES;
