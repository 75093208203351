import { ReactNode } from "react";
import styled from "styled-components";

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.darkGrey};
  padding-left: 2rem;
  padding-right: 2rem;

  color: white;
  @media (max-width: 800px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

interface LayoutProps {
  children?: ReactNode;
}
const Layout = ({ children }: LayoutProps) => {
  return <LayoutContainer>{children}</LayoutContainer>;
};

export default Layout;
