import styled from "styled-components";

interface ChipProps {
  label: string;
  color: string;
}

const ChipContainer = styled.span`
  background-color: ${(props) => props.color};
  border-radius: 10px;
  display: flex;
  margin-right: 5px;
  padding: 5px;
  color: white;
  min-width: 22px;
  height: 15px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  box-sizing: content-box;
`;

const Chip = ({ label, ...props }: ChipProps) => {
  return <ChipContainer {...props}>{label}</ChipContainer>;
};

export default Chip;
