import { useEffect, useState } from 'react';
import TableRank from '../../components/TableRank';
import Disclaimer from '../../components/Disclaimer';
import useWeightedSorting from '../../hooks/useWeightedSorting';
import pa11yResults_WCAG2A from '../../services/12-2023/pa11yResults_WCAG2A.json';
import pa11yResults_WCAG2AA from '../../services/12-2023/pa11yResults_WCAG2AA.json';
import pa11yResults_WCAG2AAA from '../../services/12-2023/pa11yResults_WCAG2AAA.json';
import FilterLevel from '../../components/FilterLevel';
import FilterCategory from '../../components/FilterCategory';
import Legend from '../../components/TableRank/Leyend';
import TableRankMobile from '../../components/TableRankMobile';
import Media from 'react-responsive';
import { AllCompliance, PageResume } from './index.types';
import HighlightQuote from '../../components/HighlightQuote';
import HeaderLogo from '../../components/LogoHeader';
import Layout from '../../commons/Layout';
import { generateSummaryToChart, getResumeFromData } from '../../utils/helpers';
import BarChartSummary from '../../components/BarChartSummary';
import TableDescriptions from '../../components/TableDescriptions';
import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Home = () => {
  const [currentPages, setCurrentPages] = useState<PageResume[]>([]);
  const [currentLevel, setCurrentLevel] = useState('aa');
  const [categories, setCategories] = useState<string[]>([]);
  const [allCompliances, setAllCompliances] = useState<AllCompliance | null>(
    null,
  );
  const [summaryForChart, setSummaryForChart] = useState<any>([]);
  const getSorting = useWeightedSorting();

  const getAllCategories = (pages: PageResume[]): string[] => {
    const result = [...new Set(pages.map((page) => page.category))];
    return result;
  };

  const handleChangeCategory = (category: string) => {
    if (!allCompliances) return;
    if (category === 'Todas') {
      const allPages: any = allCompliances[currentLevel as keyof AllCompliance];
      setCurrentPages(allPages);
      return;
    }
    const allPages: any = allCompliances[currentLevel as keyof AllCompliance];
    const filteredPages = allPages.filter(
      (page: PageResume) => page.category === category,
    );
    setCurrentPages(filteredPages);
    // Set chart summary
    const summary = generateSummaryToChart(filteredPages);
    setSummaryForChart(summary);
  };

  useEffect(() => {
    const resumeA = getResumeFromData(pa11yResults_WCAG2A);
    const resumeAA = getResumeFromData(pa11yResults_WCAG2AA);
    const resumeAAA = getResumeFromData(pa11yResults_WCAG2AAA);
    const sortedA = getSorting(resumeA);
    const sortedAA = getSorting(resumeAA);
    const sortedAAA = getSorting(resumeAAA);
    setAllCompliances({ a: sortedA, aa: sortedAA, aaa: sortedAAA });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!allCompliances) return;
    const allPages: any = allCompliances[currentLevel as keyof AllCompliance];
    const categories: string[] = getAllCategories(allPages);
    setCategories(categories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setCurrentPages(allPages);
    // Set chart summary
    const summary = generateSummaryToChart(allPages);
    setSummaryForChart(summary);
  }, [currentLevel, allCompliances]);

  return (
    <Layout>
      <Wrapper>
        <HeaderLogo />
        <HighlightQuote />

        <FilterLevel onChange={setCurrentLevel} level='aa' />
        <FilterCategory filters={categories} onChange={handleChangeCategory} />
        <BarChartSummary summaryForChart={summaryForChart} />
        <TableDescriptions summaryForChart={summaryForChart} />

        <Media query='(max-width: 767px)'>
          {(matches) =>
            matches ? (
              <TableRankMobile data={currentPages} />
            ) : (
              <TableRank data={currentPages} />
            )
          }
        </Media>
        <p>Última actualización: Febrero 2024 </p>
        <Legend />
        <Disclaimer />
      </Wrapper>
    </Layout>
  );
};

export default Home;
