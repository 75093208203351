import { ReactNode } from "react";
import { useTabsContext } from ".";

interface TabContentProps {
  children: ReactNode;
  label: string;
}

const TabContent = ({ children, label }: TabContentProps) => {
  const { selectedTab } = useTabsContext();
  if (label !== selectedTab) return null;
  return (
    <div role="tabpanel" aria-labelledby={`tab-${label}`}>
      {children}
    </div>
  );
};
export default TabContent;
