import {
  Issue,
  IssueWithDisability,
  PageResume,
} from '../pages/Home/index.types';
import ACCESSIBILITY_ISSUES from './accessibility_issues';

export const groupByKey = <T>(list: T[], key: keyof T) =>
  list.reduce(
    (hash, obj) => {
      const keyValue = obj[key] as string | number;
      return {
        ...hash,
        [keyValue]: (hash[keyValue] || []).concat(obj),
      };
    },
    {} as Record<string | number, T[]>,
  );

export const getTotalByTypeCode = (issues: Issue[], code: number) => {
  return issues.filter((issue: Issue) => issue.typeCode === code).length;
};

export const getTransCategory = (category: string) => {
  const translationsCategory: any = {
    Government: 'Gobierno',
    Retail: 'Retail',
    Sports: 'Deportes',
    News: 'Noticias',
    Airlines: 'Aerolineas',
    Finance: 'Finanzas',
    Telecommunications: 'Telecomunicaciones',
    Education: 'Educación',
  };
  return translationsCategory[category] || category;
};

const getDisability = (code: string) => {
  const disability = getDisabilityByCode(code);
  return accessibilityCategoriesDictionary[disability]
    ? accessibilityCategoriesDictionary[disability].translation
    : disability;
};

export const getResumeFromData = (pagesAll: any): PageResume[] => {
  const pages = pagesAll.filter((page: any) => page);
  const result = pages.map((page: any) => {
    const domain = new URL(page.pageUrl).hostname;

    return {
      pageUrl: page.pageUrl,
      domain,
      errors: getTotalByTypeCode(page.issues, 1),
      warnings: getTotalByTypeCode(page.issues, 2),
      notices: getTotalByTypeCode(page.issues, 3),
      category: getTransCategory(page.category),
      issues: page.issues.map((issue: Issue) => ({
        disability: getDisability(issue.code),
        ...issue,
      })),
    };
  });

  return result;
};

export const getDisabilityByCode = (codeToCheck: string) => {
  for (const category in ACCESSIBILITY_ISSUES) {
    if (ACCESSIBILITY_ISSUES[category].includes(codeToCheck)) {
      return category;
    }
  }
  return codeToCheck;
};

interface SummaryItem {
  disability: string;
  totalError: number;
  totalWarning: number;
  totalNotice: number;
}

export const generateSummaryToChart = (resume: PageResume[]): SummaryItem[] => {
  const resumen: Record<string, SummaryItem> = {};

  resume.forEach((obj) => {
    obj.issues.forEach((issue: IssueWithDisability) => {
      const disability = issue.disability;
      if (!resumen[disability]) {
        resumen[disability] = {
          disability: disability,
          totalError: 0,
          totalWarning: 0,
          totalNotice: 0,
        };
      }

      switch (issue.type) {
        case 'error':
          resumen[disability].totalError++;
          break;
        case 'warning':
          resumen[disability].totalWarning++;
          break;
        case 'notice':
          resumen[disability].totalNotice++;
          break;
      }
    });
  });

  return Object.values(resumen);
};
export const accessibilityCategoriesDictionary: any = {
  visual: {
    description:
      'Problemas de percepción visual y presentación del contenido, como contraste de color inadecuado y configuración incorrecta de la meta viewport.',
    translation: 'Percepción Visual',
  },
  pageStructure: {
    description:
      'Problemas de estructura y organización del contenido en la página, incluyendo etiquetas semánticas, listas, tablas y otros elementos estructurales.',
    translation: 'Estructura de la Página',
  },
  navigationOperability: {
    description:
      'Problemas de navegación y operabilidad del sitio web, como botones sin nombres descriptivos, problemas de enfoque y accesibilidad del teclado.',
    translation: 'Navegación y Operabilidad',
  },
  interactivity: {
    description:
      'Problemas de interactividad y funcionalidad de elementos en la página, como enlaces sin texto descriptivo o funcionalidad incorrecta.',
    translation: 'Interactividad',
  },
  compatibilityAdaptability: {
    description:
      'Problemas de compatibilidad y adaptabilidad del contenido con diferentes tecnologías de asistencia y dispositivos.',
    translation: 'Compatibilidad',
  },
  altMedia: {
    description:
      'Problemas de texto alternativo y accesibilidad de medios multimedia, como imágenes sin descripciones adecuadas o falta de subtítulos en videos.',
    translation: 'Texto Alt y Multimedia',
  },
  identificationLabeling: {
    description:
      'Problemas de identificación y etiquetado adecuado de elementos en la página, como encabezados, enlaces y regiones de contenido.',
    translation: 'Etiquetado',
  },
  clarityComprehension: {
    description:
      'Problemas de claridad y comprensión del contenido, como errores de validación de HTML, atributos aria incorrectos y elementos duplicados.',
    translation: 'Claridad y Comprensión',
  },
};
